export const slotMain = {
  name: 'slotMain',
  path: '/slotMain',
  component: () => import('@/views/member/slot/slotMain'),
  props: true,
  meta: {
    layout: 'mobile-empty',
    text: '슬롯',
    gnb: false
  }
}
